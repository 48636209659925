/* ========================================
 * Application head
 * ======================================== */

/**
 * Global custom build modernizr
 */
import '../../Resources/Public/Assets/javascripts/vendors/modernizr.js';

/**
 * Setup and load lazysizes
 */
import lazysizes from './vendors/lazysizes';

/**
 * Various browser related fixes
 */
import fixes from './lib/fixes';
import svg4everybody from 'svg4everybody';
svg4everybody();

/**
 * Setup UA classes
 */
import { isIE11 } from './lib/userAgents';

if (isIE11()) {
  document.documentElement.className += ' ie11 ';
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
